<template>
  <div class="web-component-container">
    <HeaderContainer
      v-if="Object.keys(displayHeadingContent).length"
      :nav="displayHeadingContent.links"
      :log-in-link="displayHeadingContent.logInLink[0]"
      :sign-out-link="displayHeadingContent.signOutLink[0]"
      :tracking-click-group="displayHeadingContent.trackingClickGroup"
      :logo-image="displayHeadingContent.logoImage"
      :is-prospect-switch-journey-enabled="isProspectSwitchJourneyEnabled"
      :is-promote-renewal-enabled="isPromoteRenewalEnabled"
      :user-status="userStatus"
      link-logo-to-main-website
    />
    <GlobalBanner
      v-if="bannerContent"
      :enabled="bannerContent.enabled"
      :heading="bannerContent.heading"
      :dropdown-content="bannerContent.dropdownContent"
      :pages-to-show-on="bannerContent.pagesToShowOn"
      :expiry-date="bannerContent.expiryDate"
    />
  </div>
</template>

<script>
import { mapState } from "vuex"
import UserStatusService from "@soenergy/frontend-library/src/services/UserStatusService"
import GlobalBanner from "@soenergy/frontend-library/src/components/GlobalBanner"
import ChatWidget from "@soenergy/frontend-library/src/services/ChatWidget"
import HeaderContainer from "../components/HeaderContainer"
import webComponentMixin from "./webComponentMixin"
export default {
  components: { HeaderContainer, GlobalBanner },
  mixins: [webComponentMixin],
  watch: {
    userStatus(userStatus) {
      // When userStatus arrives activate the widget with the data, otherwise just display it without any data
      if (ChatWidget && window.fwcrm) {
        window.fwcrm.on("widget:loaded", () => {
          if (userStatus && userStatus.userSignedIn) {
            ChatWidget.setUserForWidget(userStatus)
          }
        })
      }
    }
  },
  mounted() {
    if (ChatWidget) {
      ChatWidget.initWidget()
    }
  },
  computed: {
    ...mapState({
      userStatus: state => state.userStatus.userStatus
    }),
    isProspectSwitchJourneyEnabled() {
      return this.cmsContent.commonSettings.prospectSwitchJourney.includes(
        process.env.TARGET_ENV
      )
    },
    isPromoteRenewalEnabled() {
      return this.cmsContent.commonSettings.promoteRenewal.includes(
        process.env.TARGET_ENV
      )
    },
    isMaintenanceModeEnabled() {
      const isWebsiteBeMaintenanceEnabled = this.cmsContent.commonSettings.websiteBeMaintenance.includes(
        process.env.TARGET_ENV
      )
      return isWebsiteBeMaintenanceEnabled
    },
    displayHeadingContent() {
      return this.cmsContent.commonHeader
    },
    bannerContent() {
      const isMaintenanceBannerEnabled = this.cmsContent.maintenanceBanner?.content?.enabled
      return isMaintenanceBannerEnabled
        ? this.cmsContent.maintenanceBanner
        : this.cmsContent.globalBanner
    }
  },
  created() {
    UserStatusService.getUserStatusWithToken()
  }
}
</script>
