<template>
  <div v-if="cmsContent">
    <AppFooter
      :support-links="
        isLinkedToMarketingSite ? footerNavItems[0] : cmsContent.footer.nav[0]
      "
      :company-links="
        isLinkedToMarketingSite ? footerNavItems[1] : cmsContent.footer.nav[1]
      "
      :social-links="
        isLinkedToMarketingSite ? footerNavItems[2] : cmsContent.footer.nav[2]
      "
      :company-info="cmsContent.footer.companyInfo"
      :copyright="cmsContent.footer.copyright"
      :tracking-click-group="cmsContent.footer.trackingClickGroup"
      class="web-component-container"
      @reopenCookieModal="reopenCookieModal"
    />
    <CookieManager
      v-if="!isThirdPartyCookieConsentEnabled"
      ref="CookieManager"
      class="web-component-container"
      :banner-text="cmsContent.cookieBanner.bannerText[0].textContent"
      :accept-all-button="cmsContent.cookieBanner.buttons[0]"
      :manage-cookies-button="cmsContent.cookieBanner.buttons[1]"
      :has-manage-cookies="true"
      :modal-title="cmsContent.cookieModal.modalTitle"
      :modal-sub-title="cmsContent.cookieModal.modalSubTitle"
      :accept-button="cmsContent.cookieModal.acceptButton"
      :save-button="cmsContent.cookieModal.saveButton"
      :modal-description="
        cmsContent.cookieModal.modalDescription[0].textContent
      "
      :necessary-description="
        cmsContent.cookieModal.necessaryDescription[0].textContent
      "
      :performance-description="
        cmsContent.cookieModal.performanceDescription[0].textContent
      "
      :functional-description="
        cmsContent.cookieModal.functionalDescription[0].textContent
      "
      :advertising-description="
        cmsContent.cookieModal.advertisingDescription[0].textContent
      "
    >
    </CookieManager>
  </div>
</template>

<script>
import AppFooter from "@soenergy/frontend-library/src/components/AppFooter"
import CookieManager from "@soenergy/frontend-library/src/components/CookieBanner/CookieManager"
import * as CookieConsentPlugin from "@soenergy/frontend-library/src/services/CookieConsentPlugin"
import webComponentMixin from "./webComponentMixin"
import { footerNavItems } from "@soenergy/frontend-library/src/config/footerNavItems"

export default {
  components: { AppFooter, CookieManager },
  mixins: [webComponentMixin],
  data: () => ({
    isLinkedToMarketingSite:
      process.env.VUE_APP_LINKED_TO_MARKETING_SITE === "true",
    isThirdPartyCookieConsentEnabled:
      process.env.VUE_APP_USE_THIRD_PARTY_COOKIE_CONSENT === "true",
    footerNavItems
  }),
  methods: {
    reopenCookieModal() {
      if (this.isThirdPartyCookieConsentEnabled) {
        CookieConsentPlugin.openPreferencesModal()
      } else {
        this.$refs.CookieManager.showModal()
      }
    }
  }
}
</script>
