import Vuex from "vuex"
import Vue from "vue"

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {},
  actions: {},
  getters: {},
  mutations: {}
})

export default store
