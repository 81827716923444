const CMS_CONTENT = process.env.CMS_CONTENT

export default {
  provide() {
    return {
      currentProject: this.project
    }
  },
  props: {
    project: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      cmsContent: CMS_CONTENT
    }
  }
}
