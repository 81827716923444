import loadScriptFromUrl from "@soenergy/frontend-library/src/helpers/loadScriptFromUrl"

const WIDGET_ID = process.env.VUE_APP_FRESHDESK_WIDGET_ID

const prepareForWidget = () => {
  // Freshdesk integration snippet
  /* eslint-disable */
  window.fwSettings = {
    widget_id: WIDGET_ID
  }
  !(function() {
    if ("function" != typeof window.FreshworksWidget) {
      var n = function() {
        n.q.push(arguments)
      }
      ;(n.q = []), (window.FreshworksWidget = n)
    }
  })()
  /* eslint-enable */
}

export default {
  isScriptLoaded: false,
  isScriptLoading: false,
  async showWidget() {
    if (process.server || this.isScriptLoading) return

    if (this.isScriptLoaded) {
      window.FreshworksWidget("show")
    } else {
      prepareForWidget()
      this.isScriptLoading = true
      await loadScriptFromUrl(
        `https://euc-widget.freshworks.com/widgets/${WIDGET_ID}.js`
      )
      this.isScriptLoaded = true
      this.isScriptLoading = false
    }
  },
  hideWidget() {
    if (process.server) return

    if (window.FreshworksWidget) {
      window.FreshworksWidget("hide")
    }
  }
}
