<template>
  <AppHeader
    :nav="newBranding ? headerNavItems : nav"
    :log-in-link="logInLink"
    :sign-out-link="signOutLink"
    :user-status="localUserStatus"
    :link-logo-to-main-website="linkLogoToMainWebsite"
    :tracking-click-group="trackingClickGroup"
    :logo-image="logoImage"
    :is-prospect-switch-journey-enabled="isProspectSwitchJourneyEnabled"
    :is-promote-renewal-enabled="isPromoteRenewalEnabled"
    :deployment-url="vercelUrl"
    @logout="logout"
  />
</template>

<script>
import AppHeader from "@soenergy/frontend-library/src/components/AppHeader"
import AuthenticationService from "@soenergy/frontend-library/src/services/AuthenticationService"
import { isRefreshTokenAvailable } from "@soenergy/frontend-library/src/services/AuthenticationService/refreshToken"
import { isEmptyObject } from "@soenergy/frontend-library/src/helpers/objectHelper"
import { headerNavItems } from "@soenergy/frontend-library/src/config/headerNavItems"

export default {
  components: {
    AppHeader
  },
  props: {
    nav: {
      type: Array,
      default: () => []
    },
    logInLink: {
      type: Object,
      default: () => {}
    },
    signOutLink: {
      type: Object,
      default: () => {}
    },
    linkLogoToMainWebsite: {
      type: Boolean,
      default: false
    },
    trackingClickGroup: {
      type: [Array, Object],
      default: null
    },
    logoImage: {
      type: Object,
      default: () => {}
    },
    userStatus: {
      type: Object,
      default: () => {}
    },
    isProspectSwitchJourneyEnabled: {
      type: Boolean,
      default: false
    },
    isPromoteRenewalEnabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      newBranding: process.env.VUE_APP_NEW_BRANDING === "true",
      vercelUrl: process.env.VERCEL_URL,
      headerNavItems,
      localUserStatus: {}
    }
  },
  watch: {
    userStatus: {
      immediate: true,
      handler(data) {
        if (!isEmptyObject(data)) {
          this.saveUserStatus(data)
        }
      }
    }
  },
  mounted() {
    if (sessionStorage.getItem("userStatus")) {
      try {
        this.localUserStatus = JSON.parse(sessionStorage.getItem("userStatus"))
      } catch (e) {
        sessionStorage.removeItem("userStatus")
      }
    }
  },
  methods: {
    isServerSide() {
      return this.$nuxt && process.server
    },
    saveUserStatus(userStatus) {
      this.localUserStatus = userStatus
      if (!this.isServerSide()) {
        sessionStorage.setItem("userStatus", JSON.stringify(userStatus))
      }
    },
    async logout() {
      const wasRefreshTokenAvailable = isRefreshTokenAvailable()
      try {
        await AuthenticationService.logout()
      } catch (error) {
        if (wasRefreshTokenAvailable) throw error
      }
      // Allow to log out from old account, if user is still logged into it
      sessionStorage.removeItem("userStatus")
      window.location.href = process.env.VUE_APP_WEBSITE_URL
    }
  }
}
</script>
