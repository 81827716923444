import "document-register-element/build/document-register-element"
import Vue from "vue"
import vueCustomElement from "vue-custom-element"

import "@soenergy/frontend-library/src/stylesheets/vendors/bulma.scss"
import "@soenergy/frontend-library/src/stylesheets/base.scss"
import "./styles.scss"

import Mixpanel from "@soenergy/frontend-library/src/services/Mixpanel"
import AnalyticsPlugin, {
  AnalyticsService,
  GtmTracker,
  MixpanelTracker
} from "@soenergy/frontend-library/src/plugins/analytics"
import AuthenticationService from "@soenergy/frontend-library/src/services/AuthenticationService"
import UserStatusService from "@soenergy/frontend-library/src/services/UserStatusService"
import { runOnceCookieIsAllowed } from "@soenergy/frontend-library/src/services/CookiePreferences"
import { PROJECTS } from "@soenergy/frontend-library/src/config/projectRouting"
if (process.env.VUE_APP_USE_THIRD_PARTY_COOKIE_CONSENT !== "true") {
  require("@soenergy/frontend-library/build-tools/CookieConsent.js") // Do not delete, script containing self-executing code
}
import ContactFormWidget from "../services/ContactFormWidget"
import FooterWebComponent from "./FooterWebComponent.vue"
import HeaderWebComponent from "./HeaderWebComponent.vue"
import store from "./store"

Vue.use(AnalyticsPlugin, { deferInitialisation: true })
Vue.use(vueCustomElement)
AuthenticationService.init({
  store
})
UserStatusService.init({ store })

const injectStyles = styles => {
  const css = document.createElement("style")
  css.type = "text/css"
  if (css.styleSheet) {
    // IE Support
    css.styleSheet.cssText = styles
  } else {
    css.appendChild(document.createTextNode(styles))
  }

  document.getElementsByTagName("head")[0].appendChild(css)
}

const trackingConfig = process.env.TRACKING_CONFIG
const webComponentStyles = process.env.WEB_COMPONENT_STYLES
// Generated styles are injected into shadow DOM of the web components to ensure
// that they are not affecting other elements on a page where components are injected
function injectWebComponent(name, vueComponent) {
  vueComponent.store = store
  return new Promise(function(resolve) {
    Vue.customElement(name, vueComponent, {
      shadow: true,
      shadowCss: webComponentStyles,
      connectedCallback() {
        resolve(this.shadowRoot || this)
      }
    })
  })
}

function injectTracking(clickContainers) {
  // Mixpanel queueing does not work in Freshdesk dashboard
  runOnceCookieIsAllowed("performance", async () => {
    Mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN, {
      disableQueueing: true,
      projectName: PROJECTS.Help
    })
    AnalyticsService.init({
      trackers: [GtmTracker, MixpanelTracker],
      pageNames: trackingConfig,
      clickContainers,
      projectName: PROJECTS.Help,
      setGlobalLinkTracking: true
    })
    await Mixpanel.loadingInProgress
    window.mixpanel = Mixpanel.client
    document.dispatchEvent(new CustomEvent("analyticsServicesInitiated"))
  })
}

// Inject dependencies
window.ContactFormWidget = ContactFormWidget
window.UserStatusService = UserStatusService
Promise.all([
  injectWebComponent("app-header", HeaderWebComponent),
  injectWebComponent("app-footer", FooterWebComponent)
]).then(injectTracking)

const supportsShadowDOM = !!HTMLElement.prototype.attachShadow
if (!supportsShadowDOM) {
  injectStyles(webComponentStyles)
}
